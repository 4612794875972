//TYPEWRITER
import Typewriter from 'typewriter-effect/dist/core';

const hireMe = document.getElementById('hireMe');

var typewriter = new Typewriter(hireMe, {
    cursor: "|",
    loop: false,
    delay: 90,
    deleteSpeed: 70,
});

typewriter
    .pauseFor(1250)
    .typeString('<span class="text-white">hey</span>')
    .pauseFor(1000)
    .deleteChars(2)
    .pauseFor(500)
    .typeString('<span class="text-white">i</span>')
    .pauseFor(750)
    .typeString('re')
    .pauseFor(200)
    .typeString(' me ')
    .pauseFor(1000)
    .start();
